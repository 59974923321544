.row {
  display: flex;
  @media screen and (max-width: 700px) {
    flex-wrap: wrap;
  }
}
.main {
  flex: 1;
}
.aside {
  width: 30%;
  margin-left: 40px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 700px) {
    width: 100%;
    margin-left: 0;
  }

  @media print {
    .contact {
      order: 0;
    }
  }
}
